define("discourse/plugins/discourse-pavilion/discourse/routes/admin-pavilion", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    afterModel(model, transition) {
      if (transition.to.name === "admin.pavilion.index") {
        this.transitionTo("admin.pavilion.resources");
      }
    }
  });
});