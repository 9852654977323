define("discourse/plugins/discourse-pavilion/discourse/templates/connectors/admin-menu/pavilion-nav-button", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if currentUser.admin}}
    {{nav-item route="admin.pavilion" label="admin.pavilion.title"}}
  {{/if}}
  
  */
  {
    "id": "6NatbzRm",
    "block": "[[[41,[30,0,[\"currentUser\",\"admin\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"route\",\"label\"],[\"admin.pavilion\",\"admin.pavilion.title\"]]]],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `currentUser` property path was used in the `discourse/plugins/discourse-pavilion/discourse/templates/connectors/admin-menu/pavilion-nav-button.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.currentUser}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"nav-item\"]]",
    "moduleName": "discourse/plugins/discourse-pavilion/discourse/templates/connectors/admin-menu/pavilion-nav-button.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});