define("discourse/plugins/discourse-pavilion/discourse/routes/user-pavilion-membership", ["exports", "@ember/routing/route", "discourse/plugins/discourse-pavilion/discourse/models/membership"], function (_exports, _route, _membership) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    model() {
      return _membership.default.show(this.currentUser);
    },
    setupController(controller, model) {
      if (model.membership) {
        controller.setProperties({
          membership: _membership.default.create(model.membership)
        });
      }
    }
  });
});