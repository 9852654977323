define("discourse/plugins/discourse-pavilion/discourse/controllers/user-pavilion-reviews", ["exports", "@ember/controller", "@ember/object", "discourse/plugins/discourse-pavilion/discourse/models/review", "@ember/object/computed"], function (_exports, _controller, _object, _review, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    hasReviews: (0, _computed.notEmpty)("reviews"),
    page: 0,
    loadMore() {
      if (!this.canLoadMore) {
        return;
      }
      if (this.loadingMore) {
        return;
      }
      this.set("loadingMore", true);
      const page = this.page + 1;
      _review.default.list(this.currentUser, {
        page
      }).then(result => {
        this.reviews.pushObjects(result.reviews);
        if (result.reviews.length === 0) {
          this.set("canLoadMore", false);
        }
      }).finally(() => {
        this.set("loadingMore", false);
      });
    }
  }, [["method", "loadMore", [_object.action]]]));
});