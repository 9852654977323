define("discourse/plugins/discourse-pavilion/discourse/models/review", ["exports", "@ember/object", "discourse/lib/ajax", "@ember/array"], function (_exports, _object, _ajax, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Review = _object.default.extend();
  Review.reopenClass({
    list(user, data) {
      return (0, _ajax.ajax)(`/u/${user.username}/pavilion/reviews.json`, {
        type: "GET",
        data
      });
    },
    toArray(reviews) {
      return (0, _array.A)(reviews.map(review => Review.create(review)));
    }
  });
  var _default = _exports.default = Review;
});