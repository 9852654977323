define("discourse/plugins/discourse-pavilion/discourse/controllers/admin-pavilion-memberships", ["exports", "@ember/controller", "@ember/object", "discourse/plugins/discourse-pavilion/discourse/models/membership", "@ember/object/computed", "I18n"], function (_exports, _controller, _object, _membership, _computed, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    hasMemberships: (0, _computed.notEmpty)("memberships"),
    page: 0,
    loadMore() {
      if (!this.canLoadMore) {
        return;
      }
      if (this.loadingMore) {
        return;
      }
      this.set("loadingMore", true);
      const page = this.page + 1;
      _membership.default.list({
        page
      }).then(result => {
        this.memberships.pushObjects(result.memberships);
        if (result.memberships.length === 0) {
          this.set("canLoadMore", false);
        }
      }).finally(() => {
        this.set("loadingMore", false);
      });
    },
    addMembership() {
      this.get("memberships").pushObject(_membership.default.create({
        id: "new"
      }));
    },
    removeMembership(membership) {
      if (membership.id === "new") {
        this.get("memberships").removeObject(membership);
      } else {
        bootbox.confirm(_I18n.default.t("admin.pavilion.membership.remove.confirm", {
          membership_username: membership.username
        }), _I18n.default.t("cancel"), _I18n.default.t("admin.pavilion.membership.remove.label"), result => {
          if (result) {
            _membership.default.destroy(membership).then(() => {
              this.get("memberships").removeObject(membership);
            });
          }
        });
      }
    }
  }, [["method", "loadMore", [_object.action]], ["method", "addMembership", [_object.action]], ["method", "removeMembership", [_object.action]]]));
});