define("discourse/plugins/discourse-pavilion/discourse/models/membership", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "@ember/array", "I18n"], function (_exports, _object, _ajax, _ajaxError, _decorators, _array, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Membership = _object.default.extend(dt7948.p({
    typeLabel(membershipType) {
      return _I18n.default.t(`pavilion.user.membership.type.${membershipType}`);
    }
  }, [["method", "typeLabel", [(0, _decorators.default)("membership_type")]]]));
  Membership.reopenClass({
    list() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return (0, _ajax.ajax)("/admin/pavilion/memberships", {
        type: "GET",
        data
      }).catch(_ajaxError.popupAjaxError);
    },
    update(membership) {
      return (0, _ajax.ajax)(`/admin/pavilion/memberships/${membership.id}`, {
        type: "PUT",
        data: {
          membership
        }
      }).catch(_ajaxError.popupAjaxError);
    },
    destroy(membership) {
      return (0, _ajax.ajax)(`/admin/pavilion/memberships/${membership.id}`, {
        type: "DELETE"
      }).catch(_ajaxError.popupAjaxError);
    },
    show(user) {
      return (0, _ajax.ajax)(`/u/${user.username}/pavilion/membership.json`);
    },
    toArray(memberships) {
      return (0, _array.A)(memberships.map(membership => Membership.create(membership)));
    }
  });
  var _default = _exports.default = Membership;
});