define("discourse/plugins/discourse-pavilion/discourse/models/resource", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/array"], function (_exports, _object, _ajax, _ajaxError, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Resource = _object.default.extend();
  Resource.reopenClass({
    list() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return (0, _ajax.ajax)("/admin/pavilion/resources", {
        type: "GET",
        data
      }).catch(_ajaxError.popupAjaxError);
    },
    update(resource) {
      return (0, _ajax.ajax)(`/admin/pavilion/resources/${resource.id}`, {
        type: "PUT",
        data: {
          resource
        }
      }).catch(_ajaxError.popupAjaxError);
    },
    destroy(resource) {
      return (0, _ajax.ajax)(`/admin/pavilion/resources/${resource.id}`, {
        type: "DELETE"
      }).catch(_ajaxError.popupAjaxError);
    },
    toArray(resources) {
      return (0, _array.A)(resources.map(resource => Resource.create(resource)));
    }
  });
  var _default = _exports.default = Resource;
});