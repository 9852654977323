define("discourse/plugins/discourse-pavilion/discourse/controllers/user-pavilion-membership", ["exports", "@ember/controller", "@ember/object/computed"], function (_exports, _controller, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    hasResources: (0, _computed.notEmpty)("membership.resources")
  });
});