define("discourse/plugins/discourse-pavilion/discourse/routes/user-pavilion-reviews", ["exports", "@ember/routing/route", "discourse/plugins/discourse-pavilion/discourse/models/review"], function (_exports, _route, _review) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    model(params) {
      let page = params.page || 0;
      return _review.default.list(this.currentUser, {
        page
      });
    },
    setupController(controller, model) {
      controller.setProperties({
        page: model.page,
        reviews: _review.default.toArray(model.reviews)
      });
    }
  });
});