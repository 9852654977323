define("discourse/plugins/discourse-pavilion/discourse/components/pavilion-membership-row", ["exports", "@ember/component", "discourse/plugins/discourse-pavilion/discourse/models/membership", "discourse-common/utils/decorators", "discourse/plugins/discourse-pavilion/discourse/lib/pavilion"], function (_exports, _component, _membership, _decorators, _pavilion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MEMBERSHIP_TYPES = ["full", "quarter", "reserve"];
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "tr",
    attributeBindings: ["membership.id:data-membership-id"],
    classNames: ["pavilion-membership-row"],
    didReceiveAttrs() {
      this.set("currentMembership", JSON.parse(JSON.stringify(this.membership)));
    },
    membershipChanged(username, membershipType) {
      const cc = this.currentMembership;
      return cc.username !== username || cc.membership_type !== membershipType;
    },
    saveDisabled(membershipChanged, username, membershipType) {
      return !membershipChanged || !username || !membershipType;
    },
    saveClass(membershipChanged) {
      return membershipChanged ? "btn-primary save-membership" : "save-membership";
    },
    membershipTypes() {
      return (0, _pavilion.contentsMap)(MEMBERSHIP_TYPES);
    },
    actions: {
      updateUser(usernames) {
        this.membership.set("username", usernames[0]);
      },
      saveMembership() {
        const membership = this.membership;
        if (!membership.username) {
          return;
        }
        const data = {
          id: membership.id,
          username: membership.username,
          membership_type: membership.membership_type
        };
        this.set("loading", true);
        _membership.default.update(data).then(result => {
          if (result) {
            this.setProperties({
              currentMembership: result.membership,
              membership: _membership.default.create(result.membership)
            });
          } else if (this.currentMembership.id !== "new") {
            this.set("membership", JSON.parse(JSON.stringify(this.currentMembership)));
          }
        }).finally(() => {
          this.set("loading", false);
        });
      }
    }
  }, [["method", "membershipChanged", [(0, _decorators.default)("membership.username", "membership.membership_type")]], ["method", "saveDisabled", [(0, _decorators.default)("membershipChanged", "membership.username", "membership.membership_type")]], ["method", "saveClass", [(0, _decorators.default)("membershipChanged")]], ["method", "membershipTypes", [_decorators.default]]]));
});