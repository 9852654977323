define("discourse/plugins/discourse-pavilion/discourse/routes/admin-pavilion-resources", ["exports", "discourse/routes/discourse", "discourse/plugins/discourse-pavilion/discourse/models/resource"], function (_exports, _discourse, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model(params) {
      let page = params.page || 0;
      return _resource.default.list({
        page
      });
    },
    setupController(controller, model) {
      controller.setProperties({
        page: model.page,
        resources: _resource.default.toArray(model.resources)
      });
    }
  });
});