define("discourse/plugins/discourse-pavilion/discourse/routes/user-pavilion", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    afterModel(model, transition) {
      if (transition.to.name === "user.pavilion.index") {
        this.transitionTo("user.pavilion.membership");
      }
    }
  });
});