define("discourse/plugins/discourse-pavilion/discourse/templates/user/pavilion", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="user-navigation user-navigation-secondary">
    <HorizontalOverflowNav>
      <li>
        <LinkTo @route="user.pavilion.membership">
          {{d-icon "address-card"}}
          <span>{{i18n "pavilion.user.membership.label"}}</span>
        </LinkTo>
      </li>
      <li>
        <LinkTo @route="user.pavilion.reviews">
          {{d-icon "file-alt"}}
          <span>{{i18n "pavilion.user.reviews.label"}}</span>
        </LinkTo>
      </li>
    </HorizontalOverflowNav>
  </div>
  
  <section class="user-content pavilion">
    <form class="form-vertical">
      {{outlet}}
    </form>
  </section>
  
  */
  {
    "id": "AhAFFtjO",
    "block": "[[[10,0],[14,0,\"user-navigation user-navigation-secondary\"],[12],[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@route\"],[\"user.pavilion.membership\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,2],[\"address-card\"],null]],[1,\"\\n        \"],[10,1],[12],[1,[28,[35,3],[\"pavilion.user.membership.label\"],null]],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@route\"],[\"user.pavilion.reviews\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,2],[\"file-alt\"],null]],[1,\"\\n        \"],[10,1],[12],[1,[28,[35,3],[\"pavilion.user.reviews.label\"],null]],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"user-content pavilion\"],[12],[1,\"\\n  \"],[10,\"form\"],[14,0,\"form-vertical\"],[12],[1,\"\\n    \"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"horizontal-overflow-nav\",\"link-to\",\"d-icon\",\"i18n\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-pavilion/discourse/templates/user/pavilion.hbs",
    "isStrictMode": false
  });
});