define("discourse/plugins/discourse-pavilion/discourse/components/pavilion-resource-row", ["exports", "@ember/component", "discourse/plugins/discourse-pavilion/discourse/models/resource", "discourse-common/utils/decorators"], function (_exports, _component, _resource, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function arraysAreEqual(array1, array2) {
    if (array1 === undefined || array2 === undefined) {
      return true;
    }
    return array1.length === array2.length && array1.every(function (value, index) {
      return value === array2[index];
    });
  }
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "tr",
    classNames: ["pavilion-resource-row"],
    attributeBindings: ["resource.id:data-resource-id"],
    didReceiveAttrs() {
      this.set("currentResource", JSON.parse(JSON.stringify(this.resource)));
    },
    resourceChanged(name, usernames) {
      const cp = this.currentResource;
      return cp.name !== name || !arraysAreEqual(cp.usernames, usernames);
    },
    saveDisabled(resourceChanged) {
      return !resourceChanged;
    },
    saveClass(resourceChanged) {
      return resourceChanged ? "save-resource btn-primary" : "save-resource";
    },
    actions: {
      updateUsers(usernames) {
        this.resource.set("usernames", usernames);
      },
      saveResource() {
        const resource = JSON.parse(JSON.stringify(this.resource));
        if (!resource.name) {
          return;
        }
        this.set("saving", true);
        _resource.default.update(resource).then(result => {
          if (result) {
            this.setProperties({
              currentResource: result.resource,
              resource: _resource.default.create(result.resource)
            });
          } else if (this.currentResource.id !== "new") {
            this.set("resource", JSON.parse(JSON.stringify(this.currentResource)));
          }
        }).finally(() => {
          this.set("saving", false);
        });
      }
    }
  }, [["method", "resourceChanged", [(0, _decorators.default)("resource.name", "resource.usernames")]], ["method", "saveDisabled", [(0, _decorators.default)("resourceChanged")]], ["method", "saveClass", [(0, _decorators.default)("resourceChanged")]]]));
});