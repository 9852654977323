define("discourse/plugins/discourse-pavilion/discourse/templates/connectors/user-main-nav/member", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (user-viewing-self model)}}
    {{#if model.pavilion_member}}
      {{#link-to "user.pavilion"}}{{d-icon "far-building"}}{{I18n "pavilion.user.label"}}{{/link-to}}
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "z077hJNv",
    "block": "[[[41,[28,[37,1],[[30,0,[\"model\"]]],null],[[[41,[30,0,[\"model\",\"pavilion_member\"]],[[[1,\"    \"],[6,[39,2],[\"user.pavilion\"],null,[[\"default\"],[[[[1,[28,[35,3],[\"far-building\"],null]],[1,[28,[35,4],[\"pavilion.user.label\"],null]]],[]]]]],[1,\"\\n\"]],[]],null]],[]],null],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-pavilion/discourse/templates/connectors/user-main-nav/member.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/discourse-pavilion/discourse/templates/connectors/user-main-nav/member.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"user-viewing-self\",\"link-to\",\"d-icon\",\"I18n\"]]",
    "moduleName": "discourse/plugins/discourse-pavilion/discourse/templates/connectors/user-main-nav/member.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});