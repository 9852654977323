define("discourse/plugins/discourse-pavilion/discourse/discourse-pavilion-admin-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: "admin",
    map() {
      this.route("pavilion", {
        path: "/pavilion"
      }, function () {
        this.route("resources", {
          path: "/resources"
        });
        this.route("memberships", {
          path: "/memberships"
        });
      });
    }
  };
});