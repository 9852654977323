define("discourse/plugins/discourse-pavilion/discourse/templates/admin-pavilion", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <AdminNav>
    {{nav-item route="admin.pavilion.resources" label="admin.pavilion.resources"}}
    {{nav-item route="admin.pavilion.memberships" label="admin.pavilion.memberships"}}
  </AdminNav>
  
  <div class="admin-container">
    {{outlet}}
  </div>
  
  */
  {
    "id": "x/85bwNr",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,1],null,[[\"route\",\"label\"],[\"admin.pavilion.resources\",\"admin.pavilion.resources\"]]]],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"route\",\"label\"],[\"admin.pavilion.memberships\",\"admin.pavilion.memberships\"]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"admin-container\"],[12],[1,\"\\n  \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"admin-nav\",\"nav-item\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-pavilion/discourse/templates/admin-pavilion.hbs",
    "isStrictMode": false
  });
});