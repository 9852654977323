define("discourse/plugins/discourse-pavilion/discourse/routes/admin-pavilion-memberships", ["exports", "discourse/routes/discourse", "discourse/plugins/discourse-pavilion/discourse/models/membership"], function (_exports, _discourse, _membership) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model(params) {
      let page = params.page || 0;
      return _membership.default.list({
        page
      });
    },
    setupController(controller, model) {
      controller.setProperties({
        page: model.page,
        memberships: _membership.default.toArray(model.memberships)
      });
    }
  });
});